(function ($) {
  var $main_nav = $("#main-nav");
  var $toggle = $(".toggle");

  var defaultData = {
    maxWidth: false,
    customToggle: $toggle,
    levelTitles: true,
    insertClose: !0,
    closeLevels: false,
  };

  // add new items to original nav
  $main_nav
    .find("li.add")
    .children("a")
    .on("click", function () {
      var $this = $(this);
      var $li = $this.parent();
      var items = eval("(" + $this.attr("data-add") + ")");

      $li.before('<li class="new"><a>' + items[0] + "</a></li>");

      items.shift();

      if (!items.length) {
        $li.remove();
      } else {
        $this.attr("data-add", JSON.stringify(items));
      }

      Nav.update(true);
    });

  // call our plugin
  var Nav = $main_nav.hcOffcanvasNav(defaultData);

  // demo settings update

  const update = (settings) => {
    if (Nav.isOpen()) {
      Nav.on("close.once", function () {
        Nav.update(settings);
        Nav.open();
      });

      Nav.close();
    } else {
      Nav.update(settings);
    }
  };

  $(".actions")
    .find("a")
    .on("click", function (e) {
      e.preventDefault();

      var $this = $(this).addClass("active");
      var $siblings = $this
        .parent()
        .siblings()
        .children("a")
        .removeClass("active");
      var settings = eval("(" + $this.data("demo") + ")");

      update(settings);
    });

  $(".actions")
    .find("input")
    .on("change", function () {
      var $this = $(this);
      var settings = eval("(" + $this.data("demo") + ")");

      if ($this.is(":checked")) {
        update(settings);
      } else {
        var removeData = {};
        $.each(settings, function (index, value) {
          removeData[index] = false;
        });

        update(removeData);
      }
    });
})(jQuery);

//
$(document).ready(function () {
  function switchView(view) {
    if (view === "list") {
      $("#mb_products .mb_item")
        .removeClass("mb_grid_group_item")
        .addClass("mb_list_group_item");
      $("#list").addClass("active");
      $("#grid").removeClass("active");
    } else if (view === "grid") {
      $("#mb_products .mb_item")
        .removeClass("mb_list_group_item")
        .addClass("mb_grid_group_item");
      $("#grid").addClass("active");
      $("#list").removeClass("active");
    }
  }

  $("#list").click(function (event) {
    event.preventDefault();
    switchView("list");
  });

  $("#grid").click(function (event) {
    event.preventDefault();
    switchView("grid");
  });
});

$(document).ready(function () {
  // Show the popup
  $(".mb_noBg").click(function () {
    $(".mb_popup").fadeIn();
  });
  // Hide the popup
  $(".close-btn").click(function () {
    $(".mb_popup").fadeOut();
  });

  // Hide the popup if clicking outside of the popup content
  $(window).click(function (event) {
    if ($(event.target).is(".mb_popup")) {
      $(".mb_popup").fadeOut();
    }
  });
});

// search popup
$(document).ready(function () {
  // Show the popup
  $(".mb_searchclick").click(function () {
    $(".mb_gb_pop").fadeIn();
  });
  // Hide the popup
  $(".close-btn").click(function () {
    $(".mb_gb_pop").fadeOut();
  });

  // Hide the popup if clicking outside of the popup content
  $(window).click(function (event) {
    if ($(event.target).is(".mb_gb_pop")) {
      $(".mb_gb_pop").fadeOut();
    }
  });
});
$(document).ready(function () {
  $("#edit-icon").on("click", function () {
    $("#file-input").click();
  });

  $("#file-input").on("change", function (event) {
    var file = event.target.files[0];
    if (file) {
      var reader = new FileReader();
      reader.onload = function (e) {
        $("#profile-img").attr("src", e.target.result);
      };
      reader.readAsDataURL(file);
    }
  });
});

//price range js
$(document).ready(function () {
  const $rangeValue = $(".slider-container .price-slider");
  const $rangeInputValues = $(".range-input input");
  const $priceInputValues = $(".price-input select");

  // Set the price gap
  const priceGap = 500;

  // Populate dropdown options
  function populateDropdowns() {
    const minRange = 0;
    const maxRange = 10000;
    const step = 500;
    let options = "";
    for (let i = minRange; i <= maxRange; i += step) {
      options += `<option value="${i}">${i}</option>`;
    }
    $priceInputValues.each(function () {
      $(this).append(options);
    });
  }

  populateDropdowns();

  function updateRange() {
    const minp = parseInt($priceInputValues.eq(0).val());
    const maxp = parseInt($priceInputValues.eq(1).val());

    if (isNaN(minp) || isNaN(maxp)) return;

    // Ensure min and max are within allowed ranges
    if (minp < 0) {
      $priceInputValues.eq(0).val(0);
    }

    if (maxp > 10000) {
      $priceInputValues.eq(1).val(10000);
    }

    const minVal = parseInt($priceInputValues.eq(0).val());
    const maxVal = parseInt($priceInputValues.eq(1).val());

    // Adjust min and max values if they are too close
    if (minVal > maxVal - priceGap) {
      $priceInputValues.eq(0).val(maxVal - priceGap);
    }

    // Update sliders
    $rangeInputValues.eq(0).val($priceInputValues.eq(0).val());
    $rangeInputValues.eq(1).val($priceInputValues.eq(1).val());

    // Update slider visual
    updateSliderVisual();
  }

  function updateSliderVisual() {
    const minVal = parseInt($rangeInputValues.eq(0).val());
    const maxVal = parseInt($rangeInputValues.eq(1).val());

    const minPercent = (minVal / $rangeInputValues.eq(0).attr("max")) * 100;
    const maxPercent = (maxVal / $rangeInputValues.eq(1).attr("max")) * 100;

    $(".price-slider").css({
      left: `${minPercent}%`,
      right: `${100 - maxPercent}%`,
    });
  }

  function updatePriceInputs() {
    const minVal = parseInt($rangeInputValues.eq(0).val());
    const maxVal = parseInt($rangeInputValues.eq(1).val());
    const diff = maxVal - minVal;

    if (diff < priceGap) {
      if ($(this).hasClass("min-range")) {
        $rangeInputValues.eq(0).val(maxVal - priceGap);
      } else {
        $rangeInputValues.eq(1).val(minVal + priceGap);
      }
    } else {
      $priceInputValues.eq(0).val(minVal);
      $priceInputValues.eq(1).val(maxVal);
      updateSliderVisual();
    }
  }

  // Event listeners for price input elements
  $priceInputValues.on("change", updateRange);

  // Event listeners for range input elements
  $rangeInputValues.on("input", updatePriceInputs);

  // Initialize the dropdowns with the default values
  updateRange();
});
// check add class
$(document).ready(function () {
  // Check initial state of checkbox
  $(".custom-checkbox").each(function () {
    if (this.checked) {
      $(this).closest(".mb-prop-item").addClass("active");
    }
  });

  // Handle change event to toggle class
  $(".custom-checkbox").on("change", function () {
    $(this).closest(".mb-prop-item").toggleClass("active", this.checked);
  });
});
//cibil add
$(document).ready(function () {
  // Show the modal
  $(".addbtn").click(function () {
    $(".mb-cibil-popup").fadeIn();
  });

  // Close the modal
  $(".close-btn").click(function () {
    $(".mb-cibil-popup").fadeOut();
  });

  // Prevent modal from closing when clicking inside it
  $(".mb-cibil-popup").click(function (event) {
    event.stopPropagation();
  });
});
